import React from 'react'
import {Button} from '@material-ui/core';
import XLSX from "xlsx";
import AssignmentReturnedOutlinedIcon from '@material-ui/icons/AssignmentReturnedOutlined';

const ButtonCreateReportDiscart = ({data}) => {
    
    const CreateReport = async () => {
        
        let dataReport = data
        let fields;
        
        try {
                                            
            if(dataReport.length <= 0){
                dataReport = [
                    
                ]
            }
            
                 fields = Object.keys(dataReport[0])



            const wb = XLSX.utils.book_new(); 
            const ws = XLSX.utils.json_to_sheet(dataReport, { header: fields });
        
            XLSX.utils.book_append_sheet(wb, ws, "hoja1");
            XLSX.writeFile(wb, `Descartados.xlsx`);
            
        } catch (error) {
            console.log("Error al crear Reporte")
        }
    }
    
    return (
        <>
           <Button onClick={CreateReport} size="small" variant="contained" color="primary"  > Reporte Descartados </Button>  
           
             
           
        </>
    )
}

export default ButtonCreateReportDiscart
