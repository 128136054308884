import {createMuiTheme} from '@material-ui/core/styles'
import {red,grey} from '@material-ui/core/colors'

 const theme = createMuiTheme({

	palette:{
        primary:{
            light:"#fadaaa",
            main: "#ffbd59",
            dark: "#ffb442"
        }, 
        secondary:{
            light: grey[300],
            main: grey[400],
            dark: grey[500],
        
        
        
        }
    }
})


export default theme