import React from 'react'
import { PrestadorCard } from './PretadorCard'
import { ButtonHistoryBack } from '../button/ButtonHistoryBack';
import { Box } from '@material-ui/core'





export const PrestadorScreen = () => {
   
    
   
   
    return (
    <>
    <Box>
      <ButtonHistoryBack/>
      </Box>
    <PrestadorCard/>
        
    </>
        
    )
}
