import React, { useContext, useEffect, useState } from 'react'
import Card from '@material-ui/core/Card'
import Box from '@material-ui/core/Box'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions';
import { makeStyles, Typography, Button, CircularProgress, Backdrop } from '@material-ui/core'
import { deleteUser, disableUser, getDataUser, sendMailchangePassword } from '../../helpers/APISData';
import { useHistory, useParams } from 'react-router';
import DeleteIcon from '@material-ui/icons/Delete';
import { AuthContext } from '../../auth/AuthContext';


const useStyles = makeStyles((theme) => ({
    root:{
        maxWidth:650,
        padding:15,
    },
    
    rootContent:{
        display: 'flex',
        paddingLeft:20,
       
        
    },
    bold: {
        fontWeight: 600
      },
    cover:{
        width:150,
        margin:20
    },
    media: {
        height: 1, 
        paddingTop: '56.25%',
      },
    bottonRightButton:{
        display: 'flex',
        justifyContent: "flex-end",
        alignItems: "flex-end"
        
    },
    buttonEdit:{
        justifyContent:"flex-end",
         display:"flex" ,
         alignItems:"flex-start",
         
    },
    cardActions:{
        marginTop:20,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
      errorMessage:{
        color: "#e74c3c"
    },
    
    SuccsefulMessage:{
        color: "#28b463"
    },
}) )


export  const UserCard = () => {

    const {user:{token,rol,id}} = useContext(AuthContext)

    const classes = useStyles()

    let {userId} = useParams()

    const [state, setState] = useState({
        data:{},
        message:"",
        responseMessage:"",
        loading:false,
        reload:0,
        disableButton:false
    });

    useEffect( () => {
        
        setState((state) => ({
            ...state,
            loading: true,
            message: "",
        }) )

        getDataUser({
          user: userId
      },token).then(dataUser => {

        if(dataUser.message === "NO_AUTH"){
            setState((state) => ({
                ...state,
                responseMessage: "ERROR",
                message:"El usuario no tiene permisos",
                loading: false,
                data: []
            }))

        }else{
        
          if(dataUser.description === "NO USER"){
              //redirect
              
          }else if(dataUser.description === "USER"){
            
            setState((state) => ({
                ...state,
                responseMessage: dataUser.message,
                loading: false,
                data: dataUser.data
            }) )
          }

        }
          
          
      })
      
      
  }, [userId,state.reload]);

    const changeDisableUser = async () => {

        setState((state) => ({
            ...state,
            loading: true,
            message: "",
        }) )

        let body = {
            id:userId
        }

        let response = await disableUser(body,token)

        if(response.message === "ERROR"){
            setState((state) => ({
                ...state,
                responseMessage: response.message,
                loading: false,
                message: response.description
            }) )
        }else{

         setState((state) => ({
             ...state,
             responseMessage: response.message,
             loading: false,
             message: response.description,
             data:response.data,
             reload:1,
             disableButton:true
         }) )

         

        }
    }

    let history = useHistory()
    const hndlerDeleteButton = async ()=>{


        let body = {
            id:userId
        }

       await deleteUser(body,token)

        history.push("/setting/user/delete")

    }

    const changePasswordButton = async () =>{

        setState((state) => ({
            ...state,
            loading: true,
            message: "",
        }) )

        let body = {
            id:userId
        }

       let response = await sendMailchangePassword(body,token)

       if(response.message === "ERROR"){
           setState((state) => ({
               ...state,
               responseMessage: response.message,
               loading: false,
               message: response.description
           }) )
       }else{
        setState((state) => ({
            ...state,
            responseMessage: response.message,
            loading: false,
            message: response.description,
            disableButton: true
        }) )
       }
    }

let { nombre,apellido, telefono, createdAt ,email } = state.data
   
    return (
       
        <>
 

        <Card className={classes.root}>
        <Typography variant="h6" className={classes.SuccsefulMessage} color="initial">{ state.message }</Typography>

         <CardContent className={classes.rootContent}>

         <Backdrop open={state.loading} transitionDuration={{exit:400,enter:400}} className={classes.backdrop}>
                <CircularProgress/>
                </Backdrop>
            
                
           {/*  <CardMedia 
            className={classes.cover}
                image="/assets/avatar/1.png"
                title="Avatar"
                
      /> */}
                       
           
           
            <Box flexGrow={1}>
                <Typography my={2} variant="h4" color="initial">
                    {nombre },  {apellido}
                </Typography>
                <Box className={classes.rootContent} >      
                    <Box mr={1}>
                    <Typography  variant="subtitle1" className={classes.bold} color="initial">Nombre:  
                    
                    </Typography> </Box>                 
                    <Box mr={1}>
                    <Typography  variant="body1" color="initial">{nombre}
                    
                    </Typography> </Box>
                </Box>
                <Box className={classes.rootContent} > 
                    
                    <Box mr={1}>
                    <Typography variant="subtitle1" className={classes.bold} color="initial">Apellido:  
                    
                    </Typography> </Box>
                    
                    <Box mr={1}>
                    <Typography  variant="body1" color="initial">{apellido}
                    
                    </Typography> </Box>
                </Box>
                <Box className={classes.rootContent} > 
                    
                    <Box mr={1}>
                    <Typography variant="subtitle1" className={classes.bold} color="initial">Fecha. Creacion:  
                    
                    </Typography> </Box>
                    
                    <Box mr={1}>
                    <Typography  variant="body1" color="initial">{createdAt}
                    
                    </Typography> </Box>
                </Box>
                <Box className={classes.rootContent} > 
                    
                    <Box mr={1}>
                    <Typography variant="subtitle1" className={classes.bold} color="initial">Telefono:  
                    
                    </Typography> </Box>
                    
                    <Box mr={1}>
                    <Typography  variant="body1" color="initial">{telefono}
                    
                    </Typography> </Box>
                </Box>
                <Box className={classes.rootContent}  > 
                    
                    <Box mr={1}>
                    <Typography bold variant="subtitle1" className={classes.bold} color="initial">Mail:  
                    
                    </Typography> </Box>
                    
                    <Box mr={1}>
                    <Typography  variant="body1" color="initial">{email} 
                    
                    </Typography> </Box>
                </Box>
                <Box className={classes.rootContent}  > 
                    
                    <Box mr={1}>
                    <Typography variant="subtitle1" className={classes.bold} ml={2} color="initial">Estado:
                    </Typography> 
                    </Box>
                    
                    <Box mr={1}>
                    <Typography  variant="body1" className={state.data.state !== "Deshabilitado" ?  classes.SuccsefulMessage : classes.errorMessage } >{state.data.state  }
                    </Typography> 
                    </Box>
                </Box>
                
            </Box>
            { (rol === "administrador" && id !== parseInt(userId)) ? <>
                <Box className={classes.buttonEdit} > 
                   <Button size="small" href="#text-buttons"  color="primary" onClick={hndlerDeleteButton} startIcon={<DeleteIcon/>} >  Eliminar </Button>
                </Box>
            </> : <> </>}
         </CardContent>
         <CardActions className={classes.cardActions} display='flex' >

        {rol === "administrador" ? <>
         <Box flexGrow={1} >

            <>
            <Button  variant="contained" color="Primary" onClick={changePasswordButton} disabled={(state.data.state === "Deshabilitado" || state.data.disableButton ) ? true: false } disableElevation>
                    Cambiar Contraseña
            </Button>
            </>

         </Box>

         <Box>
            {(rol === "administrador" && id !== parseInt(userId))?
            <>

            <Button  variant="contained" color={(state.data.state === "Deshabilitado" ) ? "Primary" : "secondary" } onClick={changeDisableUser}  disableElevation>
             {state.data.state === "Deshabilitado" ? "Habilitar" :"Deshabilitar"}
            </Button>

            </>
            :<>

            </>}

         </Box>
        </> 

        :<>
        </>}

          
         </CardActions>

         </Card>
         </>
        
    )
}
