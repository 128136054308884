import API from '../config/axiosConfig'


export const getDataMonitor = async (body,token) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    try {
             const resp =  await API.post('/api/monitorPAMI/data',body,config).catch(err => console.log(err))
        
            return resp.data.data
        
    } catch (error) {
        
        console.log(error)

        return []
    }

}

export const sincFilesPAMI = async (file,token) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    
     const data = new FormData()
    data.append('file',file)
    const resp = await API.post('/api/monitorPAMI/sync',data,config)
    .catch(err => ({data:{message:'ERROR',
                description:"Ocurrio un error Porfavor llamar al administrador",}}))
    
    return resp.data

 
}

export const sincFilesPacientes = async (file,token) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const data = new FormData()
    data.append('file',file)
    const resp = await API.post('/api/pacientes/sync',data,config)
    .catch(err => ({data:{message:'ERROR',
                description:"Ocurrio un error Porfavor llamar al administrador",}}))


return resp.data

}

export const getLastUpdateDatePAMI = async (token) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const resp = await API.post('/api/monitorPAMI/lastDateUpdated',{},config)
    .catch(err =>({
        data:{
            data:{
                lastDate : "Error de conexión",
            },
        },
    })
)
    

return resp.data

}

export const getLastUpdateDatePaciente = async (token) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const resp = await API.post('/api/pacientes/lastDateUpdated',{},config)
    .catch(err => ({
        data:{
            data:{
                lastDate : "Intenta de nuevo",
            },
        },
    }))


return resp.data

}

export const getDataReportUploadPAMI = async (body,token) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const resp = await API.post('/api/create/report/excel',body,config)
    .catch(err => ({data:{data:{message:'Ocurrio un error'}}}))


return resp.data
}

export const getDataReportMonitor = async (body,token) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const resp = await API.post('/api/create/report/excelPAMI',body,config)
    .catch(err => ({data:{data:{message:'Ocurrio un error'}}}))


return resp.data.data
}

export const deleteDataMonitor = async (token) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const resp = await API.post('/api/monitorPAMI/sync/delete',{},config)
    .catch(err => ({data:{data:{message:'Ocurrio un error'}}}))
    

return resp.data.data

}

export const getDataPacientes = async (token) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const resp = await API.post('/api/pacientes/pacientes',{},config)
    .catch(err => ({data:{data:{descripcion:'Ocurrio un error',
                                message:'ERROR'}}}))
    

return resp.data.data

}

export const getDataPaciente = async (body,token) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const resp = await API.post('/api/pacientes/paciente',body,config)
    .catch(err => ({data:{data:{message:'Ocurrio un error'}}}))

   

return resp.data
}


export const getlistUsers = async (token) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const resp = await API.post('/api/user/users',{},config)
    .catch(err =>({data:{data:{message:'Ocurrio un error'}}}))
       

    
return resp.data
}


export const CreateUser = async (body,token) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const resp = await API.post('/api/user/createuser',body,config)
    .catch(err => ({data:{data:{message:'Ocurrio un error'}}}))

    
return resp.data
}

export const changePassword = async (body) => {

    
    const resp = await API.post('/api/auth/change-password',body)
    .catch(err => ({data:{data:{message:'Ocurrio un error'}}}))

     
return resp.data
}

export const sendMailchangePassword = async (body,token) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const resp = await API.post('/api/user/sendmailchange',body,config)
    .catch(err => ({data:{data:{message:'Ocurrio un error'}}}))

     
return resp.data
}
export const getDataUser = async (body,token) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const resp = await API.post(`/api/user/${body.user}`,{},config)
    .catch(err => ({data:{data:{message:'Ocurrio un error'}}}))

     
return resp.data
}
export const disableUser = async (body,token) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const resp = await API.post(`/api/user/user-disable`,body,config)
    .catch(err => ({data:{data:{message:'Ocurrio un error'}}}))

     
return resp.data
}
export const deleteUser = async (body,token) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const resp = await API.post(`/api/user/user-delete/${body.id}`,{},config)
    .catch(err => ({data:{data:{message:'Ocurrio un error'}}}))

     
return resp.data
}

export const loginAPI = async (body) => {


    const resp = await API.post(`/api/auth/users/login`,body)
    .catch(err => ({data:{data:{message:'Ocurrio un error'}}}))

 
return resp.data
}

export const resetPasswordAPI = async (body) => {

  
    const resp = await API.post(`/api/auth/reset/${body.urlToken}`,body)
    .catch(err => ({data:{data:{message:'Ocurrio un error'}}}))

    
return resp.data
}
