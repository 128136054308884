import React from 'react'
import {
    HashRouter as Router,
    Switch,
    Route,

  } from "react-router-dom";

import { ScreenChangePassword } from '../components/login/ScreenChangePassword';

import { ScreenResetPassword } from '../components/login/ScreenResetPassword';

export const ChangePasswordRouter = (props) => {
    
    return (

        <Router>  
                    <Switch>

                        <Route exact path="/change-password" component={ ScreenChangePassword }/>
                        <Route exact path="/reset/:token" component={ ScreenResetPassword }/>
                        
                    </Switch>
                
        </Router>
    )
}
