import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Box, makeStyles, Button } from '@material-ui/core'

import { useHistory } from 'react-router'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(() => ({
   
    SuccsefulMessage:{
        color: "#28b463"
    },
    

}))

export const UserDeteledOKScreen = () => {

        let history = useHistory()
    
    
        const handlerClickbutton = () => {
            history.push("/setting/adminuser")
        }

    return (
        <>
             <Box mb={2}>
             <Button onClick={handlerClickbutton} size="small" variant="contained" color="default"  ><ArrowBackIcon/></Button> 
            </Box>

            <Box m={5}>

            <Typography variant="h6"   color="initial">El usuario fue eliminado exitosamente</Typography>

            </Box>
        </>
    )
}
