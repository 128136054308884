
import { RouterApp } from './routers/RouterApp.js';
import { ThemeProvider } from '@material-ui/core/styles';
import  theme  from './config/themeCofig'
import { AuthContext } from './auth/AuthContext.js';
import { authReducer } from './auth/authReducer.js';
import { useEffect, useReducer } from 'react';
import jwt from "jsonwebtoken";



const init = () => {
  return JSON.parse(localStorage.getItem('user')) || {logged: false};
}

function App() {
  

  const [user, dispatch] = useReducer(authReducer, {}, init)
  
  
  useEffect(() => { 
    
    if(user.token){
      
      const {exp} = jwt.decode(user.token)
      const timeExp = (exp * 1000) - 60000
     
      if(Date.now() >= timeExp){
        localStorage.setItem('user',JSON.stringify({logged: false}))
        return;
      }
      
    }
    localStorage.setItem('user',JSON.stringify(user))
  }, [user]);

  return (
    <ThemeProvider theme={ theme }>

        <AuthContext.Provider value={{user,dispatch}}> 
          <RouterApp/>
        </AuthContext.Provider>
    </ThemeProvider>
  );
}

export default App;
