import React, { useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {Box ,CircularProgress, Typography} from '@material-ui/core';
import EnhancedTableHead from './EnhancedTableHead'; 
import { UsersRows} from './UsersRows' 
import { getlistUsers } from '../../helpers/APISData';
import { AuthContext } from '../../auth/AuthContext';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxwidth: '100%',
    
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const headTable = [
  { id: 'Usurio', numeric: false, disablePadding: false, label: 'Usurio' },
  { id: 'Rol', numeric: false, disablePadding: false, label: 'Rol' },
  { id: 'Mail', numeric: false, disablePadding: false, label: 'Mail' },
  { id: 'Telefono', numeric: false, disablePadding: false, label: 'Telefono' },
  { id: 'Estado', numeric: false, disablePadding: false, label: 'Estado' },
];


export default function UsersTable() {
  const {user:{token}} = useContext(AuthContext)
  
  const [data, setData] = React.useState({
    loading:false,
    errorMessage:null,
    data:[]

  }
);
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  
  
  React.useEffect(() => {

    setData(state => ({...state,
    loading:true}))

    getlistUsers(token).then(users => {
    
      
      if(users.message === "OK"){

        setData(state => ({...state,
          data: users.data,
          loading:false}))

        }else if(users.message === "NO_AUTH"){

          setData(state => ({...state,
            data: [],
            errorMessage:"El usuario actual no tiene permisos",
            loading:false}))
        }
    }).catch(err => {
      console.log("Error al traer lo datos de usuarios")
    })
    
  }, [])
  
  const rows = data.data

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>

        {
              data.loading ?
              <Box display="flex" my={2} justifyContent="center" >
             <CircularProgress />
              </Box>
                :
                <>

          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headTable}
              
            />
            <TableBody>

              {data.errorMessage ? 
              <>
              <Box display="flex" my={3} justifyContent="center" >
              <Typography variant="h6" color="initial">{data.errorMessage}</Typography>
              </Box>
              </>
              :<></>}

              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
            
                  return (

                       <>
                       <UsersRows data={row}/>
                       </>   
                        )}
                )
                }
              {emptyRows > 0 && (
                <TableRow style={{ height:33 }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>

        </>
        }

        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

       
      </Paper>
     
    </div>
  );
}