import React from 'react'
import {Link} from 'react-router-dom'
import { TableCell, TableRow } from '@material-ui/core'

export const UsersRows = ({data}) => {
    
    return (
        <>

                    <TableRow
                      hover
                      tabIndex={-1}
                      key={data.name}
                    > 
                      <TableCell component={Link} to={`/setting/user/${data.id}`}> {data.nombre} {data.apellido}</TableCell>
                      <TableCell >{data.rol}</TableCell>
                      <TableCell >{data.email}</TableCell>
                      <TableCell >{data.telefono}</TableCell>
                      <TableCell >{data.state}</TableCell>
                      
                    </TableRow>
        </>
    )
}
