import { Divider, ListItem, ListItemIcon,Typography,Link} from '@material-ui/core'
import { Link as LinkRouter } from 'react-router-dom';
import React, { useContext } from 'react'
import { AuthContext } from '../../../auth/AuthContext';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

export const UserSide = () => {
  
  const {user:{id,name}} = useContext(AuthContext)
 

    return (
        <>
            
            <ListItem  >
                      <ListItemIcon>
                      <AccountBoxIcon style={{color:"#004aad"}} ></AccountBoxIcon>
                      {/* <Avatar alt="Remy Sharp" src="/assets/avatar/1.png" variant="rounded" /> */}
                      </ListItemIcon>
                        <Typography>
                        <Link  style={{color:"#004aad"}} component={LinkRouter} to={`/setting/user/${id}`} >
                                 {name}
                         </Link>
                      </Typography>
                    </ListItem>
            <Divider/>
        </>
    )
}
