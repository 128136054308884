import React from 'react'
import { PacienteCard } from './PacienteCard'
import { ButtonHistoryBack } from '../button/ButtonHistoryBack';
import { Box } from '@material-ui/core'


export const PacienteScreen = () => {
    return (
        <>
            
<Box mb={2}>
      <ButtonHistoryBack/>
      </Box>
            <PacienteCard/>
        </>
    )
}
