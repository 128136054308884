import axios from 'axios';


export default axios.create({
    baseURL: 'http://168.197.48.27:80/'
    ,
//baseURL: ,
})

/* 
//baseURL: 'http://localhost:4001'
'http://168.197.48.27:80/'
*/ 