
import { useState } from "react"




export const UseFormHooks = (initialState= {}) => {
    
    const [value, setValue] = useState(initialState)



    const handlerValueChange = ({target}) => {

        let newValue = target.value
        if(target.name === "DNI" || target.name === "Telefono"){
            newValue = target.value.replace(/[^0-9]/g, '')
        }

        setValue((value)=> ({...value,
            [target.name]:newValue
        }))
    

    }

    const reset = () => {

        setValue(initialState)
    }

    return [value, handlerValueChange ,reset]
}
