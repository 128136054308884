import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles,Box, CssBaseline} from '@material-ui/core'
import FormEmail from './FormEmail'


 const useStyles = makeStyles(() => ({

    root: {
        display: 'flex',
       
      },


 }) )


export const ScreenChangePassword = () => {
    
    const classes = useStyles()
    
    return (

        <>
        <CssBaseline/>
        <Box my={5}>
          <Typography variant="h2"  align="center" color="initial">Cambiar Contraseña</Typography> 
        </Box>
            

          <FormEmail/>
        </>
    )
}
