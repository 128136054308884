import React, { useContext, useState } from 'react'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import GroupIcon from '@material-ui/icons/Group';
import SyncIcon from '@material-ui/icons/Sync';
import FaceIcon from '@material-ui/icons/Face';
import SettingsIcon from '@material-ui/icons/Settings';
import DvrIcon from '@material-ui/icons/Dvr';
import { makeStyles } from '@material-ui/core';
import {ExpandLess,ExpandMore } from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import {Link} from 'react-router-dom'
import { AuthContext } from '../../../auth/AuthContext';



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },

  colorIcons:{
    color:"#004aad",
  },
  
}));






export const ListDrawer = () => {
  
  const {user:{rol}} = useContext(AuthContext)

  const  classes = useStyles(); 
  const [open, setOpen] = useState(false);
  
  const handleClick = () => {
    setOpen(!open);
  };
  

  

  return (
        <>
            <List>
              
                <ListItem button component={Link} to="/pacientes">
                    <ListItemIcon>
                    <FaceIcon className={classes.colorIcons}/> 
                    </ListItemIcon>
                      <ListItemText primary={"Pacientes"} />
                    
                </ListItem>
                <ListItem button component={Link} to="/dashboard">
                    <ListItemIcon>
                    <DvrIcon className={classes.colorIcons}/> 
                    </ListItemIcon>
                      <ListItemText primary={"Monitor PAMI"} />
                    
                </ListItem>

               {/*  <ListItem button component={Link} to="/prestadores">
                    <ListItemIcon>
                    <AssignmentIndIcon /> 
                    </ListItemIcon>
                      <ListItemText primary={"Prestadores"} />
                    
                </ListItem> */}
                

         
            <ListItem button onClick={handleClick}>
             <ListItemIcon>
                  <SettingsIcon className={classes.colorIcons}/>
                  
                    </ListItemIcon>
                    <ListItemText  primary="Configuracion" />
                    {open ? <ExpandLess className={classes.colorIcons} /> : <ExpandMore  className={classes.colorIcons}/>}
              </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                   
                      { rol === "administrador"? <>
                    <ListItem button className={classes.nested} component={Link} to="/setting/adminuser"  >
                      <ListItemIcon>
                      <GroupIcon className={classes.colorIcons} />
                      </ListItemIcon>
                      <ListItemText primary="Adm. Usuario" />
                    </ListItem>

                        </>
                        :<>


                        </>}
                      { rol === "administrador"? <>
                    <ListItem button className={classes.nested} component={Link} to="/setting/createnewuser"  >
                      <ListItemIcon>
                      <PersonAddIcon className={classes.colorIcons} ></PersonAddIcon>
                      </ListItemIcon>
                      <ListItemText primary="Agregar Usuario" />
                    </ListItem>

                      </>
                        :<>


                        </>}
                    <ListItem button className={classes.nested} component={Link} to="/setting/syncupdata" >
                      <ListItemIcon>
                        <SyncIcon className={classes.colorIcons}  />
                      </ListItemIcon>
                      <ListItemText primary="Sinc. Datos" />
                    </ListItem>

                  </List>
                </Collapse>

            </List>
               
        </>
    )
}
