import React,{useContext, useEffect,useState} from 'react'
import Card from '@material-ui/core/Card'
import Box from '@material-ui/core/Box'
import CardContent from '@material-ui/core/CardContent'

import { makeStyles, Typography ,Divider, CircularProgress } from '@material-ui/core'
import { getDataPaciente } from '../../helpers/APISData'
import {useParams} from "react-router-dom";
import { calculate_age } from '../../helpers/utils'
import { AuthContext } from '../../auth/AuthContext'



const useStyles = makeStyles((theme) => ({
    root:{
        maxWidth:850,
    },
    
    rootContent:{
        display: 'flex', 
    },

    avatar:{
    },
    
    media: {
        height: 1, 
        paddingTop: '56.25%',
      },
    bottonRightButton:{
        display: 'flex',
        justifyContent: "flex-end",
        alignItems: "flex-end"
        
    },
    buttonEdit:{
        justifyContent:"flex-end",
         display:"flex" ,
         alignItems:"flex-start",
         
    },
    cardActions:{
        marginTop:20,
    },
    cardTitle:{
        marginBottom:10,
        
    },
    boxDataRight:{
        padding:8,
    },
    boxDataLeft:{
        paddingTop:8,
        paddingLeft:80,
    },
    boxSubtitle:{
        marginBottom:15
    },
    boxObservaciones:{
        margin:25,
    },
    subTitles:{
        marginTop:10,
        marginBottom:5
    }
    

}) )


export  const PacienteCard = () => {
    const classes = useStyles()

        let {pacienteId} = useParams()

    const [state, setstate] = useState({
        data:{},
        loading:true
    });

    
const {user:{token}} = useContext(AuthContext)

    useEffect( () => {
        
          getDataPaciente({
            paciente: pacienteId
        },token).then(dataPaciente => {
            if(dataPaciente.description === "NO PACIENTE"){
                //redirect
                
            }else if(dataPaciente.description === "PACIENTE"){
                    
                setstate({
                    data:dataPaciente.data,
                    loading:false,
                })
            } 
        })
        
    }, [pacienteId]);

    
    return (
       
            <Card  className={classes.root}>

            {state.loading ?
            
            <Box display="flex" my={2} justifyContent="center" >
                <CircularProgress />
            </Box>

            :

         <CardContent className={classes.rootContent} >
            
            
            <Box flexGrow={1}>

                <Typography  className={classes.cardTitle} variant="h4" color="initial">
                   {state.data.nombre_apellido}
                    
                </Typography>

                <Box className={classes.boxSubtitle}>
                    <Typography variant="h6" display="inline" color="initial" >
                    N. Beneficio:&nbsp; 
                    </Typography>
                    <Typography  variant="h6" display="inline"  color="primary">{state.data.nro_beneficio}</Typography>
                </Box>
                    
                
                    <Typography className={classes.subTitles} variant="h6" color="initial">Datos Personales</Typography>
                    <Divider/>
                <Box flexGrow={1} className={classes.rootContent}>
                    <Box flexGrow={1} >
                        <Box className={classes.rootContent} > 
                            <Typography variant="subtitle2" color="initial">Nombre , Apellidos: &nbsp;  </Typography>
                            <Typography  variant="body1" color="initial">{state.data.nombre_apellido}</Typography>
                        </Box>
                       
                        <Box className={classes.rootContent} > 
                            <Typography variant="subtitle2" color="initial">Genero: &nbsp; </Typography>
                            <Typography  variant="body1" color="initial">{state.data.genero}</Typography>
                        </Box>
                        <Box className={classes.rootContent}  > 
                            <Typography variant="subtitle2" color="initial">Edad:&nbsp;</Typography>
                            <Typography  variant="body1" color="initial">{calculate_age(new Date(state.data.fecha_nacimiento))}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
                        </Box>
                    </Box>
                    <Box flexGrow={1} >
                        <Box className={classes.rootContent} > 
                            <Typography variant="subtitle2" color="initial">DNI: &nbsp;  </Typography>
                            <Typography  variant="body1" color="initial">{state.data.dni}</Typography>
                        </Box>
                        <Box className={classes.rootContent} > 
                            <Typography variant="subtitle2" color="initial">Fecha N.: &nbsp; </Typography>
                            <Typography  variant="body1" color="initial">{state.data.fecha_nacimiento ? state.data.fecha_nacimiento.split(' ')[0]: '' }</Typography>
                        </Box>
                        
                    </Box>      
                </Box>
                    <Divider/>
                <Box className={classes.rootContent}>
                    <Box flexGrow={1} >
                        <Box className={classes.rootContent} > 
                            <Typography  variant="subtitle2" color="initial">Telefono :&nbsp;  </Typography>
                            <Typography  variant="body1" color="initial">{state.data.telefono}</Typography>
                        </Box>
                        
                        
                    </Box>
                    <Box flexGrow={1}>
                        <Box className={classes.rootContent}  > 
                            <Typography noWrap variant="subtitle2" color="initial">Familiar Nombre:&nbsp;  </Typography>
                            <Typography  variant="body1" color="initial">{state.data.familiar}</Typography>
                        </Box>
                       
                        
                    </Box>  

                </Box>

                <Typography variant="h6" className={classes.subTitles}  color="initial">Direccion</Typography>
                    <Divider/>
                <Box className={classes.rootContent}>
                    <Box flexGrow={1} >
                        <Box className={classes.rootContent} > 
                            <Typography  variant="subtitle2" color="initial">Provincia :&nbsp;  </Typography>
                            <Typography  variant="body1" color="initial">{state.data.provincia}</Typography>
                        </Box>
                        <Box className={classes.rootContent} > 
                            <Typography variant="subtitle2" color="initial">Localidad :&nbsp;  </Typography>
                            <Typography  variant="body1" color="initial">{state.data.localidad}</Typography>
                        </Box>
                        <Box className={classes.rootContent} > 
                            <Typography variant="subtitle2" color="initial">Barrio : &nbsp;  </Typography>
                            <Typography  variant="body1" color="initial">{state.data.barrio} </Typography>
                        </Box>
                        
                    </Box>
                    <Box flexGrow={1}>
                        <Box className={classes.rootContent}  > 
                            <Typography noWrap variant="subtitle2" color="initial">Domicilio :&nbsp;  </Typography>
                            <Typography  variant="body1" color="initial">{state.data.calle ? state.data.calle: '' }</Typography>
                        </Box>
                        <Box className={classes.rootContent}  > 
                            <Typography noWrap variant="subtitle2" color="initial">Piso & Apto /N.Casa :&nbsp;  </Typography>
                            <Typography  variant="body1" color="initial">{`${state.data.nroCalle}`}</Typography>
                        </Box>
                        
                    </Box>  

                </Box>

                <Typography variant="h6" className={classes.subTitles}  color="initial">Equipo Tratante</Typography>
                    <Divider/>
                <Box className={classes.rootContent}>
                    <Box flexGrow={1} >
                        <Box className={classes.rootContent} > 
                            <Typography  variant="subtitle2" color="initial">Medico/a C. :&nbsp;  </Typography>
                            <Typography  variant="body1" color="initial">{state.data.medico_cabecera}</Typography>
                        </Box>
                        
                        
                    </Box>  

                </Box>
                <Typography variant="h6" className={classes.subTitles}  color="initial">Observaciones area medica</Typography>
                    <Divider/>
                    

                    <Box className={classes.boxObservaciones}  > 
                    <Typography variant="h6" color="initial">Observaciones area medica</Typography>
                            <Typography align="justify" variant="body1" color="initial">{state.observaciones}</Typography>
                        </Box>

                    <Typography variant="h6"  className={classes.subTitles}  color="initial">OP</Typography>
                    <Divider/>
                        {
                            state.data.OPs.map( (OP,i) =>{
                           
                           return( <>
                    <Box className={classes.rootContent}>
                    <Box flexGrow={1} >
                        <Box className={classes.rootContent} > 
                            <Typography  variant="subtitle2" color="initial">OP Vigente :&nbsp;  </Typography>
                            <Typography  variant="body1" color="initial">{OP.OPVigente}</Typography>
                        </Box>
                    </Box>

                    <Box flexGrow={1}>
                        <Box className={classes.rootContent}  > 
                            <Typography noWrap variant="subtitle2" color="initial">F. Desde :&nbsp;  </Typography>
                            <Typography  variant="body1" color="initial">{OP.desde}</Typography>
                        </Box>
                    </Box>  
                    <Box flexGrow={1}>
                        <Box className={classes.rootContent}  > 
                            <Typography noWrap variant="subtitle2" color="initial">F. Hasta :&nbsp;  </Typography>
                            <Typography  variant="body1" color="initial">{OP.hasta}</Typography>
                        </Box>
                    </Box>  
                </Box>
                            </>)
                            })
                        }
                        

                
            </Box>


            <Box className={classes.rootContent}  > 
                    <Typography variant="h6" color="initial">Estado:&nbsp;</Typography>
                    <Typography  variant="h6" color="initial">{state.data.estado}</Typography>
                    
                </Box>
               
         </CardContent>
                        
            }
         </Card>
        
    )
}
