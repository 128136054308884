import React , {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { Box, Button, CircularProgress } from '@material-ui/core';
import { UseFormHooks } from '../../hooks/UseFormHooks';
import { changePassword } from '../../helpers/APISData';
import { useHistory } from 'react-router';


const useStyles = makeStyles((theme) => ({
  
    root: {
    display: 'flex',
    
  },
  green:{
    color: '#28b463'
    
},
red:{
  color: ' #e74c3c '
},

  paper: {
    minWidth: 800,
    padding:30,
    margin:'auto'
  },
}));

export default function FormEmail() {

  let history = useHistory()
  const classes = useStyles();
  
  const [ value, handlerValueChange ,reset ] = UseFormHooks({Email:""})
  
  const [state, setState] = useState({
    message:"",
    loading:false,
    messageResponse:""
  });
  
  let { Email } = value
  
  const handlerSendMail = async () =>{
    

    let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    //Se muestra un texto a modo de ejemplo, luego va a ser un icono
    if (!emailRegex.test(Email)) {
      
      setState( state => ({...state,
        message:"Debe ingresar un email correcto",
        messageResponse:"ERROR",
        }))

    } else {
    
      setState( state => ({...state,
        messageResponse:"",
        message:"",
      loading:true}))

        let body ={
          email:Email
        }

     let response = await changePassword(body)

     setState( state => ({...state,
     
      loading:false,
      messageResponse:response.message,
      message:response.description}))
        reset()
     }
     }
    


  return (
    <div className={classes.root}>
      
      <Paper className={classes.paper} >

      <Box flexGrow m={3} >
                  {state.messageResponse === "ERROR" ?
                  <Typography variant="h5" className={classes.red} color="initial"> - {state.message}</Typography>
                  : 
                <Typography variant="h5" className={classes.green} color="initial">  { state.message}</Typography>
                  }
        </Box>

      {
        state.loading ?

              <Box display="flex" my={2} justifyContent="center" >
                <CircularProgress />
              </Box>
                :
                <>
                {(state.messageResponse !== "OK" )?
                <>
                  
                <Box flexGrow={1}>

                  <Typography  variant="h6" color="initial">
                    Ingrese el mail:
                  </Typography>

                </Box>
                <Box flexGrow={1}>
                  <Box my={2}>
                      <TextField
                          fullWidth
                        id="Email"
                        label="Email"
                        name="Email"
                        value={Email}
                        onChange={handlerValueChange}
                      />
                  </Box>
                </Box>
                <Box mt={5} display="flex" justifyContent="center">             
              <Button variant="contained" onClick={ () => {handlerSendMail()}}  color="primary">
              Enviar
              </Button>
               </Box>
                </>:
                
                <>

                <Button variant="contained" href={"/login"}  color="primary">
              Login
              </Button>


                </>}
                </>
                }
    

      </Paper>
      
    </div>
  );
}