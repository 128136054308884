import React from 'react'
import { useHistory } from 'react-router';
import Button from '@material-ui/core/Button'

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
export const ButtonHistoryBack = () => {

    let history = useHistory()


    const handlerClickbutton = () => {
        history.goBack(-1)
    }

    return (
        <>
         <Button onClick={handlerClickbutton} size="small" variant="contained" color="default"  ><ArrowBackIcon/></Button> 
        </>
    )
}
