import React, { useState } from 'react'
import {Typography,Box} from '@material-ui/core'
import { sincFilesPacientes, sincFilesPAMI,getLastUpdateDatePAMI,getLastUpdateDatePaciente } from '../../helpers/APISData'
import { SyncCard } from './SyncCard'
import { ButtonDeleteDataMonitor } from '../button/ButtonDeleteDataMonitor';
import { ButtonHistoryBack } from '../button/ButtonHistoryBack';

const textDescriptionFilePAMI=`Archivo excel, generado por PAMI`
const textDescriptionPacientes='Archivo excel de pacientes'

export const SyncData = () => {
    
    const [deleteDatabase, setdeleteDatabase] = useState(false)

    return (
        <>

<Box mb={2}>
      <ButtonHistoryBack/>
      </Box>
            <Typography variant="h2" color="initial">
            Configuración
            </Typography>  
            
            <Box my={5}>
            
                <ButtonDeleteDataMonitor generalState={setdeleteDatabase} />
            </Box>
            
            <Box my={5}>

            <Typography variant="h5" color="initial">
            Sincronizar Datos del Paciente 
            </Typography>  
        

        <SyncCard funcionAPI={sincFilesPacientes} funcionDate={getLastUpdateDatePaciente}  dataBaseState={deleteDatabase}  id={'upload-Pacientes'} description={textDescriptionPacientes} name={'upload-Pacientes'}/> 
        
        <Typography variant="h5" color="initial">
             PAMI
            </Typography>  

            <SyncCard funcionAPI={sincFilesPAMI} funcionDate={getLastUpdateDatePAMI} dataBaseState={deleteDatabase} id={'upload-PAMI'} description={textDescriptionFilePAMI} name={'upload-PAMI'}/>  
            </Box>

        
        </>
    )
}
