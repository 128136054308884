import React from 'react'
import Typography from '@material-ui/core/Typography'
import PacientesTable from '../Tables/PacioentesTable';
import { ButtonHistoryBack } from '../button/ButtonHistoryBack';
import { Box } from '@material-ui/core'


export const PacientesScreen = () => {
    return (
        <div>
           <Typography component="div" variant="h2" color="initial">
      <Box>
      <ButtonHistoryBack/>
      </Box>
        <Box mb={5} fontStyle="normal">
        Pacientes
      </Box>
        </Typography>   



        <PacientesTable/>
     
             
        
        </div>
    )
}
