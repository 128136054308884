import React , {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { Backdrop, Box, Button, CircularProgress, ListItemText } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { resetPasswordAPI } from '../../helpers/APISData';
import { useParams } from 'react-router';

const useStyles = makeStyles((theme) => ({
  
  root: {
    display: 'flex',
    
  },

  paper: {
    minWidth: 800,
    padding:30,
    margin:'auto'
    
    
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  
  
  
  

}));

let validationSchema = yup.object({
  currentPassword: yup.string().required("Por favor ingrese la contraseña actual"),

  password1: yup
  .string()
  .required('Por favor debe ingresar una contraseña')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
    "Debe contener como minimo 8 Caracteres, un caracter MAYUSCULA, un caracter  MINISCULA, y un numero"
  ),
  password2: yup
  .string()
  .required('Por favor repita la contraseña')
  .oneOf([yup.ref('password1'), null], 'La contraseña no coinciden.')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
    "Debe contener como minimo 8 Caracteres, un caracter MAYUSCULA, un caracter  MINISCULA, y un numero "
  ),
});

export default function FormChangePasword() {
  
  const [state, setstate] = useState({
    message: "",
    messageResponde:"",
    doneOK:false,
    loading:false
  })

  const classes = useStyles();
  let {token} = useParams()

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      password1: '',
      password2: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {

      let body ={
        currentPassword:values.currentPassword,
        password:values.password1,
        urlToken:token
      }
      setstate(state => ({...state,
        loading:true,
        message:''}))

      resetPasswordAPI(body)
      .then( response => {

        setstate(state => ({...state,
        loading:false,
        message:response.description,
        doneOK:true}))

      } )
      .catch(err => {console.log(err)})
    },
  });

  return (
    <div className={classes.root}>
      
      <Paper className={classes.paper} >

      <Backdrop open={state.loading} transitionDuration={{exit:400,enter:400}} className={classes.backdrop}>
                <CircularProgress/>
                </Backdrop>


      { state.doneOK? 
      <>
        <Typography variant="h5" color="initial">{state.message}</Typography>
        
      <Box flexGrow={1} my={3}>
        <Button variant="contained" href={"/login"} fullWidth color="primary">
          Login 
        </Button>
        </Box>

      </>  :<>

    
      <Box flexGrow={1}>

        <Typography  variant="h6" color="initial">
           La Contraseña debe incluir:
        
        </Typography>

      <Box my={3}>
      <ListItemText
        primary=" - La Contraseña debe tener minimo 8 caracteres "
        />
        <ListItemText
        primary=" - La Contraseña debe contener caracteres especiales "
        />
        <ListItemText
        primary=" - La Contraseña debe contener caracteres MAYUSCULA y numeros"
        />
      </Box>

      </Box>
      
      
      <Box>
      <Typography  variant="h6" color="initial">
           Ingrese la nueva contraseña:
        
        </Typography>
      </Box>


      <form onSubmit={formik.handleSubmit}>

      <Box flexGrow={1}>
        <Box my={2}>
            <TextField
            
                fullWidth
              id="currentPassword"
              name="currentPassword"
              type="password"
              label="Contraseña actual"
              value={formik.values.currentPassword}
              onChange={formik.handleChange}
              error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
              helperText={formik.touched.currentPassword && formik.errors.currentPassword}

            />
        </Box>
      </Box>


      <Box flexGrow={1}>
      
        <Box my={2}>
            <TextField
                fullWidth
              id="password1"
              name="password1"
              label="Nueva contraseña"
              type="password"
              value={formik.values.password1}
              onChange={formik.handleChange}
              error={formik.touched.password1 && Boolean(formik.errors.password1)}
              helperText={formik.touched.password1 && formik.errors.password1}

              
            />
        </Box>
      </Box>
      <Box flexGrow={1}>

            <Box my={2}>
            <TextField
                fullWidth
              id="password2"
              name="password2"
              label="Repita contraseña"
              type="password"
              value={formik.values.password2}
              onChange={formik.handleChange}
              error={formik.touched.password2 && Boolean(formik.errors.password2)}
              helperText={formik.touched.password2 && formik.errors.password2}
              
            />
            </Box>
        </Box>
        
        <Box mt={5} display="flex" justifyContent="center">

    <Button variant="contained"  type="submit" color="primary">
    Cambiar contraseña
    </Button>
        </Box>
    </form>

            
      </>
      }


      </Paper>
      
    </div>
  );
}