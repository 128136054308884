import { makeStyles } from '@material-ui/core';
import React from 'react'
import {
    HashRouter as Router,
    Switch,
    Route,
    Redirect,
  } from "react-router-dom";
import { DashBoard } from '../components/mainMenu/DashBoardScreen';
import { PacienteScreen } from '../components/pacientes/PacienteScreen';
import { PacientesScreen } from '../components/pacientes/PacientesScreen';
import { PrestadoresScreen } from '../components/prestadores/PrestadoresScreen';
import { PrestadorScreen } from '../components/prestadores/PrestadorScreen';
import { AdmUserScreen } from '../components/setting/AdmUserScreen';
import { SyncData } from '../components/setting/SyncData';
import { UserScreen } from '../components/userSistem/UserScreen';
import MiniDrawer from '../components/ui/sideBar/MainDrawer';

import { CreateNewUserScreen } from '../components/setting/CreateNewUserScreen';
import { UserDeteledOKScreen } from '../components/userSistem/UserDeteledOKScreen';




const useStyle = makeStyles(theme => ({

    root: {
        display: 'flex',
      },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
      },
      
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },  

})
)


export const RouterDashBoard = (props) => {


    const classes = useStyle()
    return (

        <Router>

            
        <div className={ classes.root}>
            <MiniDrawer/>
            <div className={ classes.content}>
                <div className={ classes.toolbar}/>
                    <Switch>
                        <Route  exact path="/dashboard" component={ DashBoard }/>
                       
                        
                        <Route  exact path="/pacientes" component={ PacientesScreen }/>
                        <Route  exact path="/paciente/:pacienteId" component={ PacienteScreen }/>
                        
                        <Route  exact path="/prestadores" component={ PrestadoresScreen }/>
                        <Route exact path="/prestadores/prestador/:prestadoresId" component={ PrestadorScreen }/>

                        
                        <Route  exact path="/setting/adminuser" component={ AdmUserScreen }/>
                        <Route  exact path="/setting/user/delete" component={ UserDeteledOKScreen }/>
                        <Route  exact path="/setting/user/:userId" component={ UserScreen }/>
                        
                        <Route  exact path="/setting/syncupdata" component={ SyncData}/>
                        <Route  exact path="/setting/createnewuser" component={ CreateNewUserScreen}/>

                        <Redirect exact to="/dashboard"/>
                    </Switch>
                
            </div>
        </div>
        </Router>
    )
}
