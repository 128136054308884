import React from 'react'
import Typography from '@material-ui/core/Typography'

import Box from '@material-ui/core/Box';
import PrestadoresTable from '../Tables/PrestadoresTable';


import { ButtonHistoryBack } from '../button/ButtonHistoryBack';




export const PrestadoresScreen = () => {
    return (
        <div>
        <Typography component="div" variant="h2" color="initial">
        <Box>
      <ButtonHistoryBack/>
      </Box>
        <Box mb={5} fontStyle="normal">
        Prestadores
      </Box>
        </Typography>    

        <PrestadoresTable />

        
        </div>
    )
}
