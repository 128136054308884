import { Backdrop, Box, Typography ,CircularProgress, makeStyles } from '@material-ui/core'




const useStyle = makeStyles( (theme) => ({

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },

})) 





export const CircularProgressReports = ({open,progress}) => {
    const classes = useStyle()
    
    
  
  
    return (
        <>
            <Backdrop open={open} transitionDuration={{exit:400,enter:400}} className={classes.backdrop}>
                <CircularProgress size={100} value={progress}/>
                    <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    >
                <Typography variant="h4" component="div" color="primary">{`${Math.round(
                    progress,
                 )}%`}</Typography>

                    </Box>

        </Backdrop>


        </>
    )
}
