import React from 'react'
import Typography from '@material-ui/core/Typography';
import {UserCard} from './UserCard'
import Box from '@material-ui/core/Box'
import { ButtonHistoryBack } from '../button/ButtonHistoryBack';

export const UserScreen = () => {

  


    return (

        <div>
         <Typography component="div" variant="h2" color="initial">
         <Box>
      <ButtonHistoryBack/>
      </Box>
        <Box mb={5} fontStyle="normal">
             Adm. Usuario
       </Box>
     </Typography>    
          <UserCard/>
 
             
             
        </div>
    )
}
