import React,{useContext, useState} from 'react'
import MenuItem from '@material-ui/core/MenuItem';
import XLSX from "xlsx";
import { getDataReportUploadPAMI } from '../../helpers/APISData';
import { Backdrop, CircularProgress, ListItemIcon, makeStyles } from '@material-ui/core';

import ArchiveIcon from '@material-ui/icons/Archive';
import { AuthContext } from '../../auth/AuthContext';

const ButtonCreateExcel = ({desde,hasta, errorMessageState,setbackdropsReports}) => {
    
    const {user:{token}} = useContext(AuthContext)
    const CreateReport = async (fechaDesde,fechaHasta) => {
        
        setbackdropsReports((state) =>({...state,
            backdropsReports:true
        }))
        
           const timer = setInterval(() => {
            setbackdropsReports( (prevProgress) => {
                
                return {...prevProgress,
                    progressReport: (prevProgress.progressReport >= 90 ? prevProgress.progressReport - 3 : prevProgress.progressReport + 3),
                }

            }
            )
        }
          , 500);


        try {


           let dataReport = await getDataReportUploadPAMI({desde:fechaDesde,
                                                            hasta:fechaHasta},token)

         if(dataReport.message === 'ERROR'){
            errorMessageState({    
                                errorMessage: dataReport.path,
                                })

         }else{
           
            if(dataReport.data.length <= 0){
                dataReport.data = [{ 
                    "Apellido y Nombre":'',
                    "Tipo Documento":"DNI",//DNI
                    "Nro Documento":"",
                    "Estado Civil":"",
                    "Nacionalidad":"",
                    "Calle":"",
                    "Puerta":'0',
                    "Piso":'0',
                    "Dpto":'0',
                    "CP":"",
                    "Telefono":"",	
                    "Fecha Nacimiento":"",
                    "Sexo":"",
                    "CUIT":"",
                    "CUIL":"",
                    "Beneficio":"",
                    "Parentesco":"",
                    "Sucursal":"",
                    "Agencia":"",
                    "Corresponsalia":"",
                    "Afip":"",	
                    "Afiliacion":"",
                    "Formulario":"",
                    "Fecha Baja":"",
                    "Codigo Baja":"",
                    "CodDiagno":"",
                    "CodDiagnostico":"I69",
                    "Tipo Diagnostico":"1",
                    "CodPresta":"1",
                    "Codigo Prestación":" ",
                    
                    "Fecha Práctica":" ",
                    "Cantidad REAL (ACUMULADA)":" ",
                    "CANT TEORICA (AUTORIZDA)":" ",
                    "Modalidad":"2",
                    "Nro Orden":" "
                    }]
            }

            const fields = Object.keys(dataReport.data[0]);
            const wb = XLSX.utils.book_new(); 
            const ws = XLSX.utils.json_to_sheet(dataReport.data, { header: fields });
            XLSX.utils.book_append_sheet(wb, ws, "Hoja1");
            XLSX.writeFile(wb, `Reporte PAMI ${fechaDesde}-${fechaHasta}.xlsx`);
            
            setbackdropsReports( (prevProgress) => ({
                 ...prevProgress,
                    progressReport: 100,}
            )
            )
            clearInterval(timer)

            setbackdropsReports((state) =>({...state,
                backdropsReports:false,
                progressReport:0,
            }))
        }
        } catch (error) {
            console.log("Error al crear Reporte")
        }
    
    }

    
    return (
        <>


           <MenuItem onClick={ (e) => CreateReport(desde,hasta)}>  <ListItemIcon>
                    <ArchiveIcon style={{color: "#004aad" }} /> 
                    </ListItemIcon>Reporte PAMI</MenuItem>  
        </>
    )
}

export default ButtonCreateExcel
