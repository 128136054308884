import React, { useContext } from 'react'
import Typography from '@material-ui/core/Typography'
import MonitorTable from '../Tables/MonitorTable'
import {Box, Button, makeStyles} from '@material-ui/core'
import {Link} from 'react-router-dom'
import {MainContext} from './MainContext'




const useStyles = makeStyles(()=>({

    root:{
        display:"flex",
    },

    positionButton : {
        display:"flex",
        
        flexDirection:"row-reverse",
        
        
    },

    positionDate:{
        marginRight:20,
    }

}))



export const DashBoard = () => {

const classes = useStyles()

    return (
        <div>

             <Typography variant="h2" color="black">
                Monitor PAMI
             </Typography>   

            <Box className={classes.positionButton} >

                <Button component={Link} variant="contained" color="primary" to="/setting/syncupdata" disableElevation>
                sincronizar Datos
                </Button>
              
             </Box>      
             


           <MonitorTable />
        </div>
    )
}
