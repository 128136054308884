import React,{useContext, useEffect} from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import EnhancedTableHead from './EnhancedTableHead';
import { PacientesRows } from './PacientesRows';
import { getDataPacientes } from '../../helpers/APISData';
import { Box, CircularProgress } from '@material-ui/core';

import TextField from '@material-ui/core/TextField';
import { AuthContext } from '../../auth/AuthContext';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {


  const stabilizedThis = array.map((el, index) => [el, index]);
  
  stabilizedThis.sort((a, b) => {
   

    const order = comparator(a[0], b[0]);
     

    if (order !== 0) return order;
    return a[1] - b[1];
  });


  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxwidth: '100%',
    
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,

  },

  textField:{
    marginTop:20,
    marginLeft:20,
    marginRight:30,
    marginBottom:20
  },
}));

const headTable = [
  { id: 'nro_beneficio', numeric: false, disablePadding: false, label: 'N Afiliación' },
  { id: 'nombre_apellido', numeric: false, disablePadding: false, label: 'Paciente' },
  { id: 'estado', numeric: false, disablePadding: false, label: 'Estado' },
  { id: 'DNI', numeric: false, disablePadding: false, label: 'DNI' },
  { id: 'telefono', numeric: false, disablePadding: false, label: 'Telefono' },
];


export default function PacientesTable() {
  
  const {user:{token}} = useContext(AuthContext)
 const [state, setState] = React.useState({
   data:[],
   loading:true,
   message:''
    });

  const [serchResult, setserchResult] = React.useState([])
  const [serchTerm, setserchTerm] = React.useState('')
    

    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('Paciente');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    
    useEffect(() => {
      
      getDataPacientes(token).then(elements => {
        
        if(elements.message !== 'ERROR'){

          setState( state =>({...state,
            data:elements,
            loading:false
          }))

          setserchResult(elements)
          
          
        }else{
          setState(state => ({...state,
            loading:false,
            message:elements.descripcion
          }))

        }
        
      })
      
      return () => {
        setState(state => ({...state,}))
      }

    }, []); 
     
    useEffect(() => {

      setState( state =>({...state,
        loading:true}))

      let newSetPaciente = [];

       if(serchTerm === ''){
         
         setserchResult(state.data)
         setState( state =>({...state,
           loading:false}))

        }else{
     
             newSetPaciente = state.data.filter( element => {
              return element.nombre_apellido.toLowerCase().includes(serchTerm) || String(element.nro_beneficio).includes(serchTerm) 
            })
            

          setserchResult(newSetPaciente)
          setState( state =>({...state,
            loading:false}))
       }

       //clean
    }, [serchTerm]);
    
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
     
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeSerch = (event) => {

  
    setserchTerm(event.target.value)
    
  };

  const rows = serchResult

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
        {
              state.loading ?
              <Box display="flex" my={2} justifyContent="center" >
             <CircularProgress />
              </Box>
                :
                <>
               
                <TextField on onChange={handleChangeSerch}
                 className={classes.textField}
                  id="standard-search"
                   label="Buscar Paciente"
                    type="search"
                    value={serchTerm} />


          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
          
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headTable}
              
            />
            
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  
                  return (
                    <PacientesRows data={row}/>
                        )}
                )
                }
              {emptyRows > 0 && (
                <TableRow style={{ height:33 }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>

          </Table>
            </>
            }
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
     
    </div>
  );
}