import React, { useContext, useEffect } from 'react';
import {Paper , CircularProgress ,TextField, Box ,Divider, Typography, Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { MonitorRow } from './MonitorRow';
import EnhancedTableHead from './EnhancedTableHead'
import { getDataMonitor, getLastUpdateDatePaciente, getLastUpdateDatePAMI } from '../../helpers/APISData';
import { LastUpdated } from '../setting/LastUpdated';
import ToggleMenuReport from '../button/ToggleMenuReport';
import { CircularProgressReports } from './CircularProgressReports';
import { AuthContext } from '../../auth/AuthContext';

function ComparatorPrestaciones(array ,orderBy) {
 
    let moreThan ={};
    moreThan[orderBy] = 0
    for(let i = 0 ; i < array.prestaciones.length ; i++ )
    {

     
      if(array.prestaciones[i][orderBy] > moreThan[orderBy] ){
      moreThan = array.prestaciones[i]
    }
  }

  return moreThan
}


function descendingComparator(a, b, orderBy) {


  if(orderBy === "faltante" ||orderBy === "autoriada" ||orderBy === "realiada" ){

    let bFaltante = ComparatorPrestaciones(b,orderBy)
    let aFaltante = ComparatorPrestaciones(a,orderBy)

    
    if (bFaltante[orderBy] < aFaltante[orderBy]) {
      return -1;
    }
    if (bFaltante[orderBy] > aFaltante[orderBy]) {
      return 1;
    }
  }else{
    
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
 
  

}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
   
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


const headTable = [
  { id: 'nombreApellido', numeric: true, disablePadding: false, label: 'Paciente' },
  { id: 'nroBeneficio', numeric: true, disablePadding: false, label: 'N Beneficio' },
  { id: 'Prestación', numeric: false, disablePadding: false, label: 'Prestación' },
  { id: 'Prestador', numeric: false, disablePadding: false, label: 'Prestador' },
  { id: "realiada", numeric: true, disablePadding: false, label: 'Realizada' },
  { id: "autoriada", numeric: true, disablePadding: false, label: 'Autorizada' },
  { id: "faltante", numeric: true, disablePadding: false, label: 'Faltantes' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop:25
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
   textField:{
    marginTop:20,
    marginLeft:20,
    marginRight:30,
    marginBottom:20
  },
  lastUpdated:{
    marginTop:20,
    marginLeft:20,
    marginRight:30,
    marginBottom:20
  },
  faltanteVerde:{
    color: '#28b463'
    
},
faltanteRojo:{
    color: ' #e74c3c '
},
errorMessageBox:{
  marginTop:15,
  marginLeft:15,
  marginRight:15,
  marginBottom:15
},

SearchBotton:{
  marginTop:25,
  marginLeft:20,
  marginRight:0,
  marginBottom:20
},

}))


export default function MonitorTable() {
  
  const {user:{token}} = useContext(AuthContext)
  const classes = useStyles();
  const [errorMessage, seterrorMessage] = React.useState({errorMessage:''})
  
  const [state, setState] = React.useState(
    {
    data:[],
    loading:true,
    errorsMessages:'',
    backdropsReports:false,
    progressReport:0
    }
)    
   
const [serchResult, setserchResult] = React.useState([])
const [serchTerm, setserchTerm] = React.useState('')


useEffect(() => {


  let didCancel = false;
  async function getDataMonitorApi() {

   let data = await getDataMonitor({desde:fechastate.fechaDesde,
      hasta:fechastate.fechaHasta},token)

      if(!didCancel){

        setState(state => ({...state,
          data:data,
        }))
        
        setserchResult(data)
        setserchTerm("")
      }

  }
  getDataMonitorApi()
  
  return () => { didCancel = true; };
  
}, []);



useEffect(() => {

  setState( state =>({...state,
    loading:true}))
    let newSetPaciente = [];
    if(serchTerm === ""){
      setserchResult(state.data)  

    }else{
      setPage(0)
        newSetPaciente = state.data.filter( element => {
          return element.nombreApellido.toLowerCase().includes(serchTerm) || String(element.nroBeneficio).includes(serchTerm) 
   })

      setserchResult(newSetPaciente)
     
   }
   setState( state =>({...state,
    loading:false}))

   //clean
}, [serchTerm]);

    const fecha = (days = 0) =>{

      const desde =  new Date(Date.now())
      let day = 1 + days
      let month = desde.getMonth()+1
      const year = desde.getFullYear();

      if(month<10){
        month=`0${month}`
      }
      if(day<10){
        day=`0${day}`
      }
      
      return `${year}-${month}-${day}`
    }

  const [fechastate, setFechastate] = React.useState({
      fechaDesde: fecha(),
      fechaHasta: fecha(29)

  });

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  
  const [rowsPerPage, setRowsPerPage] = React.useState(6);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeSerch = (event) => {

    let regEx = /^[0-9]+$/;
    //INTENTA LIMPIAR LOS ESPACIOS VACIOS
    let newValue;
   if(!String(event.target.value.replace(/\s+/g, '')).match(regEx)){

      newValue = String(event.target.value).toLowerCase()
   }else{
    newValue = event.target.value
   }

    setserchTerm(newValue)
    
  };

  const handlerDateHasta = (e) => {
   
    setFechastate(fechastate => ({...fechastate,
                fechaHasta:e.target.value}))
  }

  const handlerDateDesde = (e) => {
   
    setFechastate(fechastate => ({...fechastate,
                fechaDesde:e.target.value}))
  }

  const handlerButtonSearchDate = () => {
    
    setState((state) =>({
      ...state,
      loading:true
    }))

    getDataMonitor({desde:fechastate.fechaDesde,
      hasta:fechastate.fechaHasta},token)
      .then(data => {
        setTimeout(() => {
          
          
          setState((state) => ({...state,
            data:data,
            loading:false
          })
          )
          
          setserchResult(state.data)
          setserchTerm(' ')
        }, 300);
        
    })



  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>

      
      <CircularProgressReports open={state.backdropsReports} progress={state.progressReport}/>

        <TableContainer>
        
        { state.loading ?
        
          <Box display="flex" my={2} justifyContent="center" >
             <CircularProgress />
             
          </Box>
          :
          <>
          <Box display="flex" className={classes.errorMessageBox}>

          <Typography variant="body1" color="primary">{`${errorMessage.errorMessage }`}</Typography> 
          </Box>
        <Box display="flex">
        <Box  flexGrow={1}>
       
              <Button  className={classes.SearchBotton}variant="contained" color="primary" size="medium" onClick={handlerButtonSearchDate}>
              Buscar
              </Button>
              
          <TextField        
            id="fechaDesde"
            label="Fecha Desde"
            type="date"
            defaultValue={fechastate.fechaDesde}
            className={classes.textField}
            onClick={()=> { setserchTerm('')}}
            onChange={handlerDateDesde}
            InputLabelProps={{
              shrink: true,
            }}
          />
       
          <TextField  
            id="fechaHasta"
            label="Fecha Hasta"
            type="date"
            defaultValue={fechastate.fechaHasta}
            className={classes.textField}
            onChange={handlerDateHasta}
            InputLabelProps={{
              shrink: true,
              
            }}
              /> 
              

             <TextField on 
             onChange={handleChangeSerch}
                 className={classes.textField}
                  id="standard-search"
                   label="Buscar Paciente"
                    type="search"
                    value={serchTerm} />   
          
       </Box>
       

        {
          state.loading ?

       
          <></>

          :
          <>
          
          <Box className={classes.lastUpdated}>
       <LastUpdated funcionDate={getLastUpdateDatePAMI} label={'Ultima actulización PAMI'} dispach={state.loading}/>
       </Box>
       <Box className={classes.lastUpdated}>
       <LastUpdated funcionDate={getLastUpdateDatePaciente} label={'Ultima actulización Pacientes'} dispach={state.loading}/>
       </Box>
       
       <Box className={classes.lastUpdated}>
      <ToggleMenuReport desde={fechastate.fechaDesde} hasta={fechastate.fechaHasta} backdropsReports={setState} seterrorMessage={seterrorMessage} />
      </Box>
    
        </>
        }

        
        </Box>
        <Divider/>

          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
          >

            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headTable}      
            />
            <TableBody>
              { 

                 stableSort(serchResult, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((paciente, index) => {
                  
                  let otherRows = [];
                  let firstRow = [];
                  
                  if(serchResult.length > 0){
                                       
                     if(serchResult.length >= 1){

                       firstRow = paciente.prestaciones[0]
                      otherRows = paciente.prestaciones.slice(1)

                     }
                       

                  return (
                    <>
                    <TableRow
                      
                      tabIndex={-1}
                      key={index}>

                        <TableCell hover align="center" rowSpan={6} >{paciente.nombreApellido} </TableCell>
                        <TableCell align="left" rowSpan={6} >{paciente.nroBeneficio}</TableCell>  

                        <TableCell >{firstRow.prestacion}</TableCell>
                        <TableCell align=''>{firstRow.prestador }</TableCell>
                        <TableCell align='center'>{firstRow.realiada} </TableCell>
                        <TableCell align='center'>{firstRow.autoriada}</TableCell>
                        <TableCell align='center'><Typography variant="body1" className={ firstRow.faltante === 0 ? classes.faltanteVerde : classes.faltanteRojo }>{firstRow.faltante }</Typography></TableCell>
                        </TableRow>
                       
                        <MonitorRow data={otherRows}/>
                        

                    </>
                  )
                  }else{
                    return (<>
                    <Typography variant="body1" color="initial">debe contactar al Administrador</Typography>

                    </>)
                  }
                  
                })
                }
              {/* emptyRows > 0 && (
                <TableRow style={{ height:33 }}>
                  <TableCell colSpan={6} />
                </TableRow>
              ) */}
            </TableBody>
          </Table>
          </>
        }
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[6,12,18]}
          component="div"
          count={serchResult.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
     
    </div>
  );
}