

import {Typography,
    Box,
    makeStyles,
     CardContent,
     Card,
     Button,
     Input,
     InputLabel,
     Backdrop,
     CircularProgress,
     
    } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../auth/AuthContext'
import ButtonCreateReportDiscart from '../button/ButtonCreateReportDiscart'
import { LastUpdated } from './LastUpdated'



const maxWidthsie = 650

const useStyles = makeStyles( (theme) => ({
    
    Root:{
        display: 'flex',
        maxWidth: maxWidthsie,
    },
    
    card:{
        marginTop:20,
        padding:15,
        marginBottom:20,
        maxWidth: 650
    },

    errorUpload:{
        color: ' #e74c3c '
    },

    greatUpload:{
        color: '#28b463'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },


}))

export  const SyncCard = ({funcionAPI,funcionDate,id,name,description,dataBaseState}) => {

    const {user:{token}} = useContext(AuthContext)
   
    const classes = useStyles()

    const [state, setstate] = useState({
        selectedFile:'',
        message:'',
        done:false,
        backdrops:false,
        descartados:[]})

    const handleInputChange = (e) =>{
        
        setstate(state => ({...state,
            selectedFile: e.target.files[0],
            message:'',
            done:false,
            descartados:[],
            
        }))
 }

useEffect(() => {

     funcionDate(token).then(resp => {

        // recicla la funcion de la fecha para validar si hay datos en la base de datos
         if(resp.data.lastDate === "00/00/00 00:00:00" ){
              
             setstate(state => ({...state,
                 done:false,
                 message:'',
                 selectedFile:'',
             }))
             return;
         }else{
             setstate(state => ({...state,
                 done:true,
                 message:'',
                 selectedFile:'',
             }))
             return;
         }

     }).catch(err =>{setstate(state => ({...state,
        done:true,
        message:'Error conexion',
        })
    ) } )

}, [dataBaseState])


 const  sendFile = async () => {
    
    if(state.selectedFile !== ''){
        
        setstate(state =>({...state,
            backdrops:true}))

        const response = await funcionAPI(state.selectedFile,token)
        
        if(response.message === "OK"){
            
            setstate( state =>({...state,
            lastUpdateDate:response.data.lastDate,
            done:true,
            backdrops:false,
            message: response.description,
            descartados:response.data.descartados}))
                
        }else if(response.message !== "OK"){

          setstate(state =>({
              ...state,
              backdrops:false,
              message:response.path
          })

          )  
        }
        }else{

        setstate(state => ({...state,
            message:'El archivo no esta cargado'

        }) )
    }

}

    return (
        <>
                       
                <Card className={classes.card}>

                <Backdrop open={state.backdrops} transitionDuration={{exit:400,enter:400}} className={classes.backdrop}>
                <CircularProgress/>
                </Backdrop>

                <Box className={classes.Root}>
                    
                    <Box flexGrow={1}> 
                        <Typography variant="h6" color="initial"> Carga de Archivo</Typography> 
                    </Box>
                   

                    <LastUpdated funcionDate={funcionDate} dispach={state.done} label={'Ultima actulización'} ></LastUpdated>
                   

                </Box>
                    <Box > 
                        <Typography variant="body1" color="initial">{description}</Typography> 
                    </Box>
                <CardContent >
                <Box className={classes.Root}>
                    <Box flexGrow={1}>
                    
                    <InputLabel  htmlFor={`${id}`}  >
                            <Input
                            id={`${id}`}
                            name={`${name}`}
                            style={{display:'none'}}
                            type="file"
                            onChange={handleInputChange}
                                />
                             
                             <Button variant="contained" color="primary"  component="span" disableElevation>
                             examinar
                                </Button>
                            </InputLabel>
                    <Box>
                        <Box>
                        <Typography variant="body1" color="initial">{state.selectedFile === '' ? state.message : state.selectedFile.name }</Typography>
                        </Box>
                    { !state.done ?
                    <Typography variant="body1" className={state.done ? classes.greatUpload :classes.errorUpload} > {state.message}</Typography>
                        :
                        <>
                        <Typography variant="body1" className={state.done ? classes.greatUpload :classes.errorUpload} > {state.message}</Typography>
                        </>
                    }
                    </Box>

                    </Box>
                    <Box>
                    {  
                         (state.descartados.length > 0 && state.done) ? 
                         
                      <ButtonCreateReportDiscart data={state.descartados}/>
                   :
                      <></>
                   }
                    { state.done

                    
                        
                    ? 
                    <>
                        <Typography variant="h6" align="center" className={classes.greatUpload}> Archivo Sincronizado</Typography>
                        <Typography variant="body1" align="center" className={classes.greatUpload}> Para Sincronizar  un nuevo archivo debe eliminar la base de datos</Typography>
                    </>
                     :  
                        <>
                        <Button
                            onClick={sendFile}
                            variant="contained"
                            color="primary"
                            >
                            Sincronizar
                            </Button>
                        
                        </>
                     }
                     
                        
                    </Box>
                    </Box>
                </CardContent>   
            </Card>
            
 

        </>
    )
}
