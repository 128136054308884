import React,{useContext, useState} from "react"
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import { Box, Button, CircularProgress ,MenuItem} from "@material-ui/core";
import { UseFormHooks } from "../../hooks/UseFormHooks";
import { CreateUser } from "../../helpers/APISData";
import { AuthContext } from "../../auth/AuthContext";



const dataRol = [
    {
        value: 'colaborador',
        label: 'Colaborador',
      },
    /* {
        value: 'administrador',
        label: 'Administrador',
      }, */
]

const dataGender = [
    {
        value: 'masculino',
        label: 'Masculino',
      },
    {
        value: 'femenino',
        label: 'Femenino',
      },
]

const useStyles = makeStyles((theme) => ({
  
    root: {
    display: "flex",
    
  },
 
  paper: {
    minWidth: 600,
    padding:30,
    margin:"auto"
},
    errorMessage:{
    color: "#e74c3c"
},

SuccsefulMessage:{
    color: "#28b463"
},


}));

export const CreateNewUser = () => {
    const classes = useStyles();

    const {user:{token}} = useContext(AuthContext)


    const [value, handlerValueChange ,reset] = UseFormHooks({
        Nombre:"",
        Apellido:"",
        DNI:"",
        Genero:"",
        Email:"",
        Rol:"",
        Telefono:"",
    })

    const [validateForm, setvalidateForm] = useState({
        emailmessage:"",
        successful:"",
        loading:false
    })


    const { Nombre,Apellido,Email,Telefono,DNI,Genero,Rol } = value

    const handlerSubmit =async () =>{


        setvalidateForm( state => ({...state,
            loading:true
            }))

        let send = true
        for(let i in value ){
            if(value[i] === ""){
                
                setvalidateForm(state => ({...state,
                                     successful:"",
                                     emailmessage:` - Debe rellenar todos los campos, el campo \"${(i)}\" esta vacio.`,
                                     loading:false}))
                send = false
                break;
            }  
        }
        if(send){
        let body = {
            "nombre":Nombre,
            "apellido":Apellido,
            "dni":DNI,
            "gender":Genero,
            "email":Email, 
            "rol":Rol,
            "telefono":Telefono,
            "state":"Por verificar"
        }

        let response = await CreateUser(body,token)
        console.log(response)
        if(response.message === "ERROR"){
            setvalidateForm( state => ({...state,
                successful:"",
                emailmessage:response.description,
                loading:false
                }))

        }else{
            reset()
            setvalidateForm(state => (
            {...state,
                emailmessage:"",
                loading:false,
                successful:response.description}))

                reset()
            }
        
        }
    }
    return(
       <div className={classes.root}>
      
      <Paper className={classes.paper} >

        { validateForm.loading ?
              <Box display="flex" my={2} justifyContent="center" >
             <CircularProgress />
              </Box>
                : 

            <>
      <Box flexGrow={1}>

        <Typography  variant="h5" color="initial">
          Nuevo usuario  
        </Typography>

        <Box mt={2} flexGrow={1}>

          {
              validateForm.successful.length > 0 ?
              <Typography  variant="h6" className={classes.SuccsefulMessage}>
             {validateForm.successful}
             </Typography>
        :
        <></>
          }
          {
              validateForm.emailmessage.length > 0 ?

              <Typography  variant="body1" className={classes.errorMessage}>
             {validateForm.emailmessage}
             </Typography>
            :
             <></>
          }


        </Box>

        <Box flexGrow={1}>

            <Box my={2}>
                <TextField
                fullWidth
                id="Nombre"
                label="Nombre"
                value={Nombre}
                name="Nombre"
                onChange={handlerValueChange}
                
                />
            </Box>
        </Box>
        <Box flexGrow={1}>

            <Box my={2}>
                <TextField
                fullWidth
                id="Apellido"
                label="Apellido"
                name="Apellido"
                value={Apellido}
                onChange={handlerValueChange}
                
                />
            </Box>
        </Box>
        <Box flexGrow={1}>

            <Box my={2}>
                <TextField
                
                inputMode="numeric"
                fullWidth
                id="DNI"
                label="DNI"
                name="DNI"
                value={DNI}
                onChange={handlerValueChange}
                
                />
            </Box>
        </Box>
        <Box flexGrow={1}>

            <Box my={2}>
                <TextField
                select
                fullWidth
                id="Genero"
                label="Genero"
                name="Genero"
                value={Genero}
                onChange={handlerValueChange}
                >
                 {dataGender.map((option) => (
                 <MenuItem key={option.value} value={option.value}>
                     {option.label}
                 </MenuItem>
                ))}


                </TextField>
            </Box>
        </Box>
        <Box flexGrow={1}>
            <Box my={2}>
                <TextField
                fullWidth
                id="Email"
                name="Email"
                label="Email"
                value={Email}
                onChange={handlerValueChange}
                
                />
            </Box>
        </Box>
       
        <Box flexGrow={1}>

            <Box my={2}>
                <TextField
                fullWidth
                id="Telefono"
                name="Telefono"
                label="Telefono"
                value={Telefono}
                onChange={handlerValueChange}
                
                />
            </Box>
        </Box>
        <Box flexGrow={1}>

            <Box my={2}>
                <TextField
                select
                fullWidth
                id="Rol"
                name="Rol"
                label="Rol"
                value={Rol}
                onChange={handlerValueChange}
                >
                {dataRol.map((option) => (
                 <MenuItem key={option.value} value={option.value}>
                     {option.label}
                 </MenuItem>
                ))}
            
                </TextField>   
            </Box>
        </Box>
     
      </Box>
        <Box mt={5} display="flex" justifyContent="center">
            <Button onClick={handlerSubmit} variant="contained"  color="primary">
            Crear nuevo usuario
            </Button>
        </Box>
        </>
        }
      </Paper>
      
    </div>
    )
}
