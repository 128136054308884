import React from 'react'
import {Link} from 'react-router-dom'
import { TableCell, TableRow } from '@material-ui/core'

export const PacientesRows = ({data}) => {
   
    return (
        <>
            <TableRow
                hover
                tabIndex={-1}
                key={data.nro_beneficio}
            > 
                <TableCell >{data.nro_beneficio}</TableCell>
                <TableCell component={Link} to={`/paciente/${data.nro_beneficio}`}> {data.nombre_apellido}</TableCell>
                <TableCell >{data.estado}</TableCell>
                <TableCell >{data.nroDocumento}</TableCell>
                <TableCell >{data.telefono}</TableCell>
                
            </TableRow>

        </>
    )
}
