import React, { useContext, useEffect, useState } from 'react'
import {Box,Typography, CircularProgress, makeStyles} from '@material-ui/core'
import { AuthContext } from '../../auth/AuthContext'


const useStyle = makeStyles( (theme) =>({
      
    updatedDate:{
        color: '#28b463'
        
    },
    opdatedDate:{
        color: ' #e74c3c '
    }

    }))


    
export const LastUpdated = ({funcionDate,dispach ,label}) => {

    const {user:{token}} = useContext(AuthContext)
    const classes = useStyle()

    const [state, setstate] = useState(
        {
            date:'',
            loading:true
        })


useEffect(() => {
   
    funcionDate(token)
    .then( resp => {
        setstate(state => ({...state,
            date:resp.data.lastDate,
            loading:false})
        ) 
    })
    .catch(err =>{setstate(state => ({...state,
        date:'Error conexion',
        loading:false})
    ) } )
   
}, [dispach]);


    const isSameDateNow = (state) => {
        
       const lastUpdated = new Date(new Date(state.date).toDateString())    
      
       const now = new Date(new Date(Date.now()).toDateString())

        return  lastUpdated.getTime() === now.getTime()

    }

       
   
   


    return (
        <>
        <Box>
        <Typography variant="subtitle2" color="initial" noWrap>{label}  </Typography>
            {   state.loading 
            ? 
            <CircularProgress></CircularProgress>
            :
            <Typography mr={1} variant="body1" className={ isSameDateNow(state) === true ? classes.updatedDate : classes.opdatedDate} >{state.date}</Typography>
            
            }

        </Box>



        </>
    )
}
