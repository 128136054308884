import React from 'react'
import {Typography,Box} from '@material-ui/core'
import { CreateNewUser } from './CreateNewUser'
import { ButtonHistoryBack } from '../button/ButtonHistoryBack';


export const CreateNewUserScreen = () => {
    return (
        <>
         <Box my={5}>
         
         <Box mb={2}>
      <ButtonHistoryBack/>
      </Box>
            <Typography variant="h2" color="initial"> Crear Nuevo usuario</Typography>
         </Box> 

        <CreateNewUser/>

        </>
    )
}
