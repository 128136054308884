import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { AuthContext } from '../../auth/AuthContext';
import { types } from '../../types/types';
import { useHistory } from 'react-router';
import { loginAPI } from '../../helpers/APISData';
import { UseFormHooks } from '../../hooks/UseFormHooks';
import { Backdrop, CircularProgress } from '@material-ui/core';
import jwt from "jsonwebtoken"


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        Eureka 
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(assets/backgroud/architectureLogin.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(15, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  errorMessage:{
    color: "#e74c3c"
},
backdrop: {
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
},

}));


export function SignInSide() {

  const [value, handlerValueChange ,reset] = UseFormHooks({
    email:"",
    password:"",
  })

  let {email , password } = value
  const { dispatch } = useContext(AuthContext)

  const history = useHistory()

  const [state, setstate] = useState({message:"",
                                      responseMessage:"",
                                      loading:false})

  const handleLogin = async () =>{
    
      let body = {
        "email":email,
      "password":password,
      }

      setstate( state => ({
        ...state,
        loading:true
      }))
      let responseToken = await loginAPI(body)
      

      if(responseToken.message === "OK"){

        if(responseToken.token === 0 ){

          setstate( state => ({
            ...state,
            message:responseToken.description,
            loading:false
          }))
        }else{

         const userToken = jwt.decode(responseToken.token)
      
         
          if(userToken.user.state === "Por verificar"){
           
            history.replace(`/user/#/reset/${userToken.user.tokenChangePassword}`)

            setstate( state => ({
              ...state,
              loading:false
            }))
            return;
          }else if(userToken.user.state === "Activo"){


            setstate( state => ({
              ...state,
              loading:false
            }))

                      dispatch({
                        type: types.login,
                        payload:{
                          name:userToken.user.nombre,
                          rol:userToken.user.rol,
                          id:userToken.user.id,
                          token:responseToken.token
                        }
                      })
            history.replace(`/`)
                  
             
          }

        }
      }else if(responseToken.message === "ERROR") {

        setstate( state => ({
          ...state,
          message:responseToken.description,
          loading:false
        }))
      }
  
    }



  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />

    
      <Backdrop open={state.loading} transitionDuration={{exit:400,enter:400}} className={classes.backdrop}>
                <CircularProgress/>
                </Backdrop>

      <Grid item xs={false} sm={6} md={8} className={classes.image} />
      <Grid item xs={12} sm={6} md={4} component={Paper} elevation={6} square>
        <div className={classes.paper}>

          <Typography component="h1" variant="h4">
            INICIAR SESIÓN 
          </Typography>
          

          <Grid className={classes.form}>


          <Typography component="body1"  className={classes.errorMessage} align="center" color="initial">
            {state.message}
          </Typography>

            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Correo electronico"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handlerValueChange}
              value={email}
            />
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              name="password"
              label="contraseña"
              type="password"
              id="password"
              value={password}
              autoComplete="current-password"
              onChange={handlerValueChange}
            />
            
            <Button
            
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={handleLogin}
              
            >
             INICIAR SESIÓN
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="user/#/change-password" variant="body2">
                  Olvidaste la contraseña?
                </Link>
              </Grid>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
        
        </div>
      </Grid>
    </Grid>
  );
}