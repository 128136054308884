import React, { useContext } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    
  } from "react-router-dom";
import { AuthContext } from '../auth/AuthContext';
import { SignInSide } from '../components/login/SignInSide';
import { ChangePasswordRouter } from './ChangePasswordRouter';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './publicRoute';
import { RouterDashBoard } from './RouterDashBoard';

export const RouterApp = () => {

    const {user} = useContext(AuthContext);
    
    return (
        <div>
        <Router>
            <Switch>
                <PublicRoute exact path="/login"
                 isAuthenticated={user.logged}
                 component={ SignInSide }/>

                <PublicRoute exact path="/user/"
                 isAuthenticated={user.logged}
                 component={ ChangePasswordRouter }/>

                <PrivateRoute  path="/" 
                component={ RouterDashBoard }
                isAuthenticated={user.logged}
                />
            </Switch>
        </Router>
        </div>
    )
}
