import Typography from '@material-ui/core/Typography'
import { makeStyles, TableCell, TableRow } from '@material-ui/core'
import React from 'react'


const useStyles = makeStyles( () => ({

    faltanteVerde:{
        color: '#28b463'
        
    },
    faltanteRojo:{
        color: ' #e74c3c '
    }




}))


export const MonitorRow = ({data}) => {
      
    const classes = useStyles()
        
   
    return (
        <>
            {
                data.map( row =>{
                
                    return(
                        <>    
                        <TableRow >
                            <TableCell >{row.prestacion}</TableCell>
                            <TableCell align=''>{ row.prestador }</TableCell>
                            <TableCell align='center'>{row.realiada} </TableCell>
                            <TableCell align='center'>{row.autoriada}</TableCell>
                            <TableCell align='center'><Typography variant="body1" className={ row.faltante === 0 ? classes.faltanteVerde : classes.faltanteRojo }>{row.faltante } </Typography></TableCell> 
                        </TableRow>

                        </>
                    
                        )
                    }
                    
                )
            }
    
        </>
    )
}
