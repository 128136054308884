import React from 'react'
import Card from '@material-ui/core/Card'
import Box from '@material-ui/core/Box'
import CardContent from '@material-ui/core/CardContent'

import { makeStyles, Typography, Button } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
    root:{
        maxWidth:650,
    },
    
    rootContent:{
        display: 'flex', 
    },

    avatar:{
    },
    
    media: {
        height: 1, 
        paddingTop: '56.25%',
      },
    bottonRightButton:{
        display: 'flex',
        justifyContent: "flex-end",
        alignItems: "flex-end"
        
    },
    buttonEdit:{
        justifyContent:"flex-end",
         display:"flex" ,
         alignItems:"flex-start",
         
    },
    cardActions:{
        marginTop:20,
    }
}) )

export  const PrestadorCard = () => {

    const classes = useStyles()
    return (
       
            <Card  className={classes.root}>
         <CardContent className={classes.rootContent} >
            
            <Box width={150}>

            </Box>
            
            <Box flexGrow={1}>
                <Typography variant="h4" color="initial">
                    Prestador 1
                </Typography>
                <Box className={classes.rootContent} > 
                    <Typography variant="subtitle2" color="initial">Nombre:  </Typography>
                    <Typography  variant="body1" color="initial">PEDRO</Typography>
                </Box>
                <Box className={classes.rootContent} > 
                    <Typography variant="subtitle2" color="initial">Periodo:  </Typography>
                    <Typography  variant="body1" color="initial">10/20/2020</Typography>
                </Box>
                <Box className={classes.rootContent} > 
                    <Typography variant="subtitle2" color="initial">Telefono:  </Typography>
                    <Typography  variant="body1" color="initial">11 4445-3345</Typography>
                </Box>
                <Box className={classes.rootContent}  > 
                    <Typography variant="subtitle2" color="initial">Mail:  </Typography>
                    <Typography  variant="body1" color="initial">Pedro@gmail.com</Typography>
                </Box>
                
            </Box>
                <Box className={classes.buttonEdit} > 
                   <Button size="small"   variant="text" > Editar</Button>
                   
                </Box>
            
         </CardContent>
    
         </Card>
        
    )
}
