import React, { useContext } from 'react'
import MenuItem from '@material-ui/core/MenuItem';
import XLSX from "xlsx";
import { getDataReportMonitor } from '../../helpers/APISData';
import DvrIcon from '@material-ui/icons/Dvr';
import { ListItemIcon } from '@material-ui/core';
import { AuthContext } from '../../auth/AuthContext';



const ButtonCreateExcelMonitor = ({desde,hasta}) => {
    
    const {user:{token}} = useContext(AuthContext)
    const CreateReport = async (fechaDesde,fechaHasta) => {
        
        try {
           let dataReport = await getDataReportMonitor({desde:fechaDesde,
                                                        hasta:fechaHasta
                                                    },token)
            
            console.log(dataReport)                                     
            if(dataReport.length <= 0){
                dataReport = [
                    {
                    Nombre_Apellido:"",
                    Nro_Beneficio:"",
                    
                    Prestacion_M: "",
                    Prestador_M:  "",
                    R_Medica :"",
                    Autorizada_M: "",
                    Faltante_M:   "",     

                    Prestacion_E:"",
                    P_Emfermero: "",
                    R_Enfermero :"",
                    Autorizada_E:"",
                    Faltante_E:  "",
        
                    Prestacion_K: "",
                    P_Kinesiologa:"",
                    R_Kinesiologa:"",
                    Autorizada_K: "",
                    Faltante_K:   "",

                    Prestacion_T:"",
                    P_Terapista: "",
                    R_Terapista :"",
                    Autorizada_T:"",
                    Faltante_T:  "",
        
                    Prestacion_F: "" ,
                    P_Fonoaudiologa:"",
                    R_Fonoaudiologa:"",
                    Autorizada_F:   "",
                    Faltante_F:     "",
        
                    Prestacion_C: "",
                    P_Cuidador:   "",
                    R_Cuidador :  "",
                    Autorizada_C: "",
                    Faltante_C:   "",
                }]
            }

            const fields = Object.keys(dataReport[0])
            const wb = XLSX.utils.book_new(); 
            const ws = XLSX.utils.json_to_sheet(dataReport, { header: fields });
        
            XLSX.utils.book_append_sheet(wb, ws, "Hoja1");
            XLSX.writeFile(wb, `Monitor-${fechaDesde}-${fechaHasta}.xlsx`);
            
        } catch (error) {
            console.log("Error al crear Reporte")
        }
    }
    
    return (
        <>
           <MenuItem onClick={ (e) => CreateReport(desde,hasta)}> 
                <ListItemIcon>
                <DvrIcon  style={{color: "#004aad" }}/> 
                    </ListItemIcon> Reporte Monitor</MenuItem>  
        </>
    )
}

export default ButtonCreateExcelMonitor
