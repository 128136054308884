import React, { useContext, useState } from 'react'
import {Backdrop, Button, CircularProgress, makeStyles, Typography,Box}  from '@material-ui/core'
import { deleteDataMonitor } from '../../helpers/APISData'
import { AuthContext } from '../../auth/AuthContext';



const useStyles = makeStyles( (theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },

      greatDelete:{
        color: '#28b463'
    },
   
}))


export const ButtonDeleteDataMonitor = ({generalState}) => {

    
const {user:{token}} = useContext(AuthContext)
    
    const classes = useStyles()

    const [state, setstate] = useState({
        backdrops:false,
        message:"",
        done: false})

    const handlerClickDeleteButton = async () => {

       

        setstate(state => ({...state,
            backdrops:true}))

             let response = await deleteDataMonitor(token)

            setstate(state => ({...state,
                backdrops:false,
                message:response.message,
                done:true}))
        
        generalState(true)

        
    
        
        }

    return (
        <>
            { state.done ?
            <> 
                <Typography variant="h6"  className={classes.greatDelete}>{state.message}</Typography>
                <Box mt={2}>
                
                </Box>

            </> 
            :
             <>
            <Button variant="contained" onClick={handlerClickDeleteButton} color="primary"> Eliminar Datos Monitor </Button>
             </>   
            }



    
        <Backdrop open={state.backdrops} transitionDuration={{exit:400,enter:400}} className={classes.backdrop}>
                <CircularProgress/>
                </Backdrop>

        </>
    )
}
