import React from 'react'
import {Link} from 'react-router-dom'
import { TableCell, TableRow } from '@material-ui/core'

export const PrestadoresRows = ({data}) => {
    

    //user fecth
    return (
        <>

                    <TableRow
                      hover
                      tabIndex={-1}
                      key={data.id}
                    > 
                      <TableCell >{data.periodo}</TableCell>
                      <TableCell component={Link} to={`/prestadores/prestador/${data.id}`}> {data.name}</TableCell>
                      <TableCell >{data.dni}</TableCell>
                      <TableCell >{data.benef}</TableCell>
                      <TableCell >{data.paciente}</TableCell>
                      <TableCell >{data.telefono}</TableCell>
                      
                    </TableRow>

       
        </>
    )
}
