import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Box, makeStyles, Button } from '@material-ui/core'
import UsersTable from '../Tables/UsersTable'
import {Link} from 'react-router-dom'
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { ButtonHistoryBack } from '../button/ButtonHistoryBack';

const useStyles = makeStyles(() => ({
   
    buttonAdd:{
     
        display:'flex',
        
    }
}))

export const AdmUserScreen = () => {

    const classes = useStyles()

    return (
        <div>
        <Box mb={2}>
      <ButtonHistoryBack/>
      </Box>
     <Box mb={5} fontStyle="normal">
        <Typography component="div" variant="h2" color="initial">
      Adm. Usuario
     </Typography>    
    </Box>
    <Box my={3} flexDirection="row-reverse" className={classes.buttonAdd}>
       
    <Button
        variant="contained"
        color="primary"
        startIcon={<GroupAddIcon/>}
        component={Link}
        to="/setting/createnewuser"
        disableElevation
      >
        Agreagar Usuario
      </Button>


    </Box>

     <UsersTable/>
             
     </div>
    )
}
